<template>
  <md-card>
    <md-card-content>
      <div class="row-btns">
        <slot name="btns"></slot>
      </div>
      <md-table v-model="queriedData" md-card @md-selected="onSelected">
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          md-selectable="multiple"
          md-auto-select
        >
          <md-table-cell md-label=""></md-table-cell>
          <md-table-cell
            v-for="(data, i) in tableInfo"
            :key="i"
            :md-label="data.label"
            :md-sort-by="data.key"
            >{{ item[data.key] }}</md-table-cell
          >
        </md-table-row>
      </md-table>
    </md-card-content>
    <!-- <md-card-actions md-alignment="space-between">
        <div class>
          <p class="card-category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <Pagination
          class="pagination-no-border pagination-success"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        ></Pagination>
        <md-table-toolbar class="per-page" :style="{ marginBottom: '20px' }">
          <md-field>
            <label for="orders">Per page</label>
            <md-select v-model="pagination.perPage" name="orders">
              <md-option
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
                >{{ item }}</md-option
              >
            </md-select>
          </md-field>
        </md-table-toolbar>
      </md-card-actions> -->
  </md-card>
</template>

<script>
export default {
  props: {
    showQCBtn: Boolean,
    showFilter: Boolean,
    showItemFilter: Boolean,
    showLineItemFilter: Boolean,
    tableData: {
      type: Array,
      default: () => [],
    },
    tableInfo: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      // if (this.searchedData.length > 0) {
      //   result = this.searchedData;
      // }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
      // return this.searchedData.length > 0
      //   ? this.searchedData.length
      //   : this.tableData.length;
    },
  },
  data() {
    return {
      showDialog: false,
      showMultTable: false,
      selectedItems: [],
      pagination: {
        perPage: 250,
        currentPage: 1,
        perPageOptions: [250, 500, 1000],
        total: 0,
      },
    };
  },
  methods: {
    handleEdit() {
      this.showDialog = true;
    },
    onSelected(data) {
      this.selectedItems = data;
      this.$emit("onSelected", this.selectedItems);
    },
    getAlternateLabel(count) {
      let plural = "";
      if (count > 1) plural = "s";
      return `${count} item${plural} selected`;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex !important;
}

table {
  display: flex !important;
  flex-direction: column !important;
}

.md-table {
  height: 220px !important;
}

.row-btns {
  display: flex;
}

.md-table-alternate-header {
  position: static !important;
}
</style>
